
.question-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  container-type: inline-size;
}

.question-container > h2 {
  max-width: 760px;
  margin: 0;
  font-weight: normal;
  overflow: auto;
  font-size: 3cqw;
}

.action-button {
  margin-bottom: 10px;
  font-size: 32px;
  border: 1px solid #707070;
  border-radius: 20px;
  width: 166px;
  height: 90px;
}

.star {
  font-size: 8cqw !important;
  color: #EDFF00 !important;
  margin-right: 1cqw !important;
}



@media screen and (width <=1024px) {
  .action-button {
    font-size: 31px;
    border: 1px solid #707070;
    border-radius: 20px;
    width: 169px;
    height: 98px;
  }
}